import React from 'react';
import styled from '@emotion/styled';
import { RichText } from 'prismic-reactjs';

import { BREAKPOINTS } from 'utils/constants';

interface IPhotoProps {
  backgroundImage: string;
}

const Container = styled.div({
  width: 'calc(100%/3)',
  minWidth: 280,
  [BREAKPOINTS[1200]]: {
    width: 'calc(100%/2)',
  },
  [BREAKPOINTS[870]]: {
    width: '100%',
    maxWidth: 450,
  },
});

const Content = styled.div({
  margin: 40,
  [BREAKPOINTS[870]]: {
    marginRight: 0,
  },
  [BREAKPOINTS[800]]: {
    margin: '40px 0',
  },
});

const PhotoContainer = styled.div<IPhotoProps>((props) => ({
  cursor: 'default',
  backgroundImage: `url(${props.backgroundImage})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  display: 'flex',
  height: 420,
  marginBottom: 16,
  position: 'relative',
  borderRadius: 4,
  zIndex: 0,
  span: {
    opacity: 0,
    transition: 'opacity 0.5s ease-out',
  },
  '&:after': {
    content: '" "',
    position: 'absolute',
    opacity: 0,
    backgroundColor: 'black',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 0,
    borderRadius: 4,
    transition: 'opacity 0.5s ease-out',
  },
  ':hover span': {
    opacity: 1,
    transition: 'opacity 0.9s ease-out',
  },
  ':hover:after': {
    opacity: 0.6,
    transition: 'opacity 0.9s ease-out',
  },
}));

const DescriptionContainer = styled.div((props) => ({
  color: props.theme.colors.white,
  fontSize: 15,
  letterSpacing: 1,
  lineHeight: '23px',
  padding: 32,
  zIndex: 1,
}));

const NameContainer = styled.div((props) => ({
  color: props.theme.colors.white,
  fontSize: 18,
  lineHeight: '30px',
  letterSpacing: 1,
  fontWeight: 600,
}));

const TitleContainer = styled.div((props) => ({
  color: props.theme.colors.white,
  fontSize: 15,
  opacity: 0.4,
  letterSpacing: 1,
  lineHeight: '27px',
}));

const Contributor = ({ name, photo, title, description }) => {
  const formattedDescription = RichText.asText(description);

  if (!photo || !name || !title || !description) {
    return null;
  }

  return (
    <Container>
      <Content>
        <PhotoContainer backgroundImage={photo}>
          <DescriptionContainer>
            <span>{formattedDescription}</span>
          </DescriptionContainer>
        </PhotoContainer>
        <NameContainer>{RichText.asText(name).toUpperCase()}</NameContainer>
        <TitleContainer>{RichText.asText(title)}</TitleContainer>
      </Content>
    </Container>
  );
};

export default Contributor;

import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';

import SEO from 'components/SEO';
import Contributor from 'components/Contributor';
import { BREAKPOINTS } from 'utils/constants';

const Container = styled.div((props) => ({
  paddingTop: 206,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: props.theme.colors.blue,
  minHeight: '100vh',
}));

const ContributorsContainerWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  margin: '80px 100px 160px 100px',
  [BREAKPOINTS[800]]: {
    margin: '80px 20px 160px 20px',
  },
});

const ContributorsContainer = styled.div({
  display: 'flex',
  maxWidth: 1239,
  flexWrap: 'wrap',
});

const TitleWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
});

const TitleCenterWrapper = styled.div({
  width: '100%',
  maxWidth: 1239,
  padding: '0 100px',
  [BREAKPOINTS[800]]: {
    padding: '0 20px',
  },
});

const Title = styled.div((props) => ({
  color: props.theme.colors.white,
  fontSize: 40,
  letterSpacing: 4,
  fontWeight: 100,
  padding: '0 40px',
  [BREAKPOINTS[800]]: {
    padding: '0',
  },
}));

const TitleFirstWord = styled.span({
  fontWeight: 600,
  letterSpacing: 2,
});

export const query = graphql`
  query ContributorsPageQuery {
    allPrismicContributorsPage {
      nodes {
        dataRaw
      }
    }
  }
`;

const Contributors = ({ data }) => {
  const pageData = data.allPrismicContributorsPage.nodes[0].dataRaw;

  const arrayOfTitle = RichText.asText(pageData.title_for_contributors)?.split(' ');
  const firstWord = arrayOfTitle.shift();
  const whatsLeft = arrayOfTitle.join(' ');

  return (
    <Container>
      <SEO
        pageTitle={pageData.page_title && RichText.asText(pageData.page_title)}
        ogTitle={pageData.og_title && RichText.asText(pageData.og_title)}
        twitterTitle={pageData.twitter_title && RichText.asText(pageData.twitter_title)}
        pageDescription={pageData.page_description && RichText.asText(pageData.page_description)}
        ogDescription={pageData.og_description && RichText.asText(pageData.og_description)}
        twitterDescription={
          pageData.twitter_description && RichText.asText(pageData.twitter_description)
        }
        pageImage={pageData?.page_image?.url}
        pageImageAlt={pageData?.page_image?.alt}
        twitterImage={pageData?.twitter_image?.url}
        twitterImageAlt={pageData?.twitter_image?.alt}
        ogImage={pageData?.og_image?.url}
        ogImageAlt={pageData?.og_image?.url?.alt}
      />
      <TitleWrapper>
        <TitleCenterWrapper>
          <Title>
            <TitleFirstWord>{firstWord} </TitleFirstWord>
            {whatsLeft}
          </Title>
        </TitleCenterWrapper>
      </TitleWrapper>
      <ContributorsContainerWrapper>
        <ContributorsContainer>
          {pageData.contributor.map((contributor) => (
            <Contributor
              key={RichText.asText(contributor.name)}
              name={contributor.name}
              title={contributor.title}
              photo={contributor.photo?.url}
              description={contributor.description}
            />
          ))}
        </ContributorsContainer>
      </ContributorsContainerWrapper>
    </Container>
  );
};

export default Contributors;
